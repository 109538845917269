import {Route, Routes} from "react-router-dom";
import {MainLayout} from "pages/MainLayout";
import {About} from "pages/About";
import { YearView } from "pages/Calendar";

export const App = () => {

  return (
      <Routes>
          <Route path={'/'} element={<MainLayout/>}>
              <Route path={'/'} element={<YearView year={2025}/>}/>

              {/* Footer Part */}
              <Route path={'/about'} element={<About/>}/>
          </Route>
      </Routes>
  )
}