import {apiSlice} from "core/api/apiSlice";


export type HolidayEntry = {
    name: string
    day: number
    month: number
}

export type GetHolidaysPayload = {
    countryIso: string
    year: number
    month: number
}

const calendarApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getHolidays: builder.query<HolidayEntry[], GetHolidaysPayload>({
            query: ({countryIso, year, month}) => ({
                url: `calendar/holidays?countryIso=${countryIso}&year=${year}&month=${month}`
            })
        })
    })
})

export const {
    useGetHolidaysQuery
} = calendarApi