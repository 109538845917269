import React, { useState } from 'react';
import { AppBar, Toolbar, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { AppLogo } from './AppLogo';
import { SearchBar } from "../../../Components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { clearToken } from "core/store/authSlice";

export const Header = () => {
    const dispatch = useDispatch();

    const [isOpenSignUp, setIsOpenSignUp] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleSignOut = () => {
        dispatch(clearToken());
        setAnchorEl(null);
    };

    const handleSignInButton = () => {
        if (!isOpenSignUp) {
            setIsOpenSignUp(true);
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppBar style={{ background: '#2C2C2C', zIndex: 1299, margin: 0 }} position='fixed'>
                <Toolbar variant='dense'>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Grid item>
                            <AppLogo />
                        </Grid>

                        <Grid item xs={6}>
                            <SearchBar />
                        </Grid>

                        <Grid item>
                            <Grid item>
                                <IconButton color="inherit" onClick={handleMenuOpen}>
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem>1</MenuItem>
                                    <MenuItem>2</MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar variant='dense' />
        </>
    );
};