import React from 'react';
import {TextField, Box, Button} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SearchBar = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
                variant='outlined'
                placeholder='Search...'
                fullWidth
                size='small'
                sx={{
                    backgroundColor: '#FFFFFF',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    '& fieldset': {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    },
                }}
            />
            <Button
                variant='contained'
                sx={{
                    minWidth: '40px',
                    height: '40px',
                    backgroundColor: '#1976D2',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: '#145CA8',
                    },
                }}
            >
                <SearchIcon />
            </Button>
        </Box>
    );
};