import React, {useState} from 'react';
import {Autocomplete, Box, Grid, TextField} from '@mui/material';
import {MonthView} from "pages/Calendar/components/MonthView";

interface YearViewProps {
    year: number;
}

export const YearView: React.FC<YearViewProps> = ({ year }) => {
    const countries = ['BY', 'PL', 'US'];
    const [country, setCountry] = useState('BY');

    return (
        <Grid>
            <Grid sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <Autocomplete
                    id="combo-box-countries"
                    options={countries}
                    value={country}
                    onChange={(event, newValue) => {
                        setCountry(newValue ?? '');
                    }}
                    getOptionLabel={(option) => option}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                />
            </Grid>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {Array.from({ length: 12 }, (_, i) => (
                    <MonthView key={i} month={i + 1} year={year} country={country}/>
                ))}
            </Box>
        </Grid>
    );
};