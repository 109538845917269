import {Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

export const AppLogo = () => {

    const navigate = useNavigate()

    const handleLogoNameClick = () => {
        navigate('/')
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }

    return (
        <Grid
            container
            justifyContent='center'
            alignItems='center'
            columnGap='8px'
            onClick={handleLogoNameClick}
            sx={{ cursor: 'pointer' }}
        >
            <Grid item>
                <Typography variant='body2' color='common.white' component='span'>
                    3Scrum
                </Typography>
            </Grid>
        </Grid>
    )
}