import {StrictMode} from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import './styles/index.css'
import {App} from 'App'
import {store} from 'core/store'
import {createTheme, ThemeProvider} from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById('root')!)

const appTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        color: '#131314',
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                        '& fieldset': {
                            borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                            borderColor: '#E0E0E0',
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                            borderColor: '#A4A4A4',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#767676',
                            borderWidth: '1px',
                        },
                    },
                },
            },
        },
        // custom styles
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: '0',
                    marginBottom: '2px',
                },
            },
        },
    },
    shadows: [
        'none',
        '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 6px 30px rgba(0, 0, 0, 0.06), 0px 8px 10px rgba(0, 0, 0, 0.1)',
        '0px 6px 20px 0px rgba(19, 19, 20, 0.05)',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
})

root.render(
    <StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <ThemeProvider theme={appTheme}>
                    <App/>
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    </StrictMode>
)
