// store/slices/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    token: localStorage.getItem('token') || null,
    status: localStorage.getItem('token') ? 'authenticated' : 'idle',
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken(state, action: PayloadAction<string>) {
            state.token = action.payload;
            state.status = 'authenticated';
            localStorage.setItem('token', action.payload);
        },
        clearToken(state) {
            state.token = null;
            state.status = 'idle';
            localStorage.removeItem('token');
        },
        setError(state) {
            state.status = 'error';
        },
    },
});

export const { setToken, clearToken, setError } = authSlice.actions;