import {Outlet} from "react-router-dom";
import {CssBaseline, styled} from '@mui/material'
import {Header} from "pages/MainLayout/Compoments/Header";
import {SearchBar} from "Components/SearchBar";
import {Footer} from "pages/MainLayout/Compoments/Footer";

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
})

const StyledMain = styled('main')(() => ({
    flexGrow: 1,
    width: '100%',
    margin: '24px 0px',
    marginBottom: '40px'
}))

export const MainLayout = () => {
    return (
        <StyledContainer
            sx={{
                overflowY: 'scroll',
            }}
        >
            <CssBaseline/>
            <Header/>
            <StyledMain>
                {<Outlet/>}
            </StyledMain>
            <Footer/>
        </StyledContainer>
    )
}