import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "core/constants/api-route-constants";
import {RootState} from "core/store";
import {ApiTagType} from "core/constants/api-tag-types-constants";

export const apiSlice = createApi({
    reducerPath: 'apiSlice',
    tagTypes: [
        ApiTagType.Favorite
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: () => ({}),
});