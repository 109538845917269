import React from 'react';
import {Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from '@mui/material';
import {useGetHolidaysQuery} from "core/api/calendarApi";

interface MonthViewProps {
    month: number;
    year: number;
    country: string;
}

export const MonthView: React.FC<MonthViewProps> = ({ month, year, country }) => {
    const daysInMonth = new Date(year, month, 0).getDate();
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const startingDayOfWeek = (firstDayOfMonth.getDay() + 6) % 7;

    const calendarDays = Array.from({ length: 35 }).map((_, index) => {
        const dayNumber = index - startingDayOfWeek + 1;
        return dayNumber > 0 && dayNumber <= daysInMonth ? dayNumber : null;
    });

    const monthName = new Date(year, month - 1).toLocaleString('default', { month: 'long' });

    const {data} = useGetHolidaysQuery({countryIso: country, year, month})

    return (
        <Box sx={{ margin: 2, maxWidth: 400 }}>
            <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 2 }}>
                {monthName} {year}
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map(day => (
                            <TableCell key={day} align="center">{day}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.from({ length: 5 }).map((_, weekIndex) => (
                        <TableRow key={weekIndex}>
                            {Array.from({ length: 7 }).map((_, dayIndex) => {
                                const day = calendarDays[weekIndex * 7 + dayIndex];
                                const holiday = data?.find(h => h.day == day && h.month == month)

                                if (day && dayIndex > 4) {
                                    return (
                                        <TableCell key={dayIndex} align="center" sx={{background: '#e75885', padding: 1, border: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <span>{day || ''}</span>
                                        </TableCell>
                                    );
                                }

                                if (!!holiday) {
                                    return (
                                        <TableCell key={dayIndex} align="center" sx={{background: '#e75885', padding: 1, border: '1px solid rgba(224, 224, 224, 1)' }}>
                                            <Tooltip title={holiday.name}>
                                                <span>{day || ''}</span>
                                            </Tooltip>
                                        </TableCell>
                                    );
                                }

                                return (
                                    <TableCell key={dayIndex} align="center" sx={{ padding: 1, border: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {day || ''}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};