import {Button, Grid, styled} from "@mui/material";
import {useNavigate} from "react-router-dom";

const StyledFooter = styled('footer')({
    borderTop: '1px solid gray',
    background: '#f8f9fa',
    padding: '20px',
    textAlign: 'center',
});

export const Footer = () => {
    const navigate = useNavigate();

    return (
        <StyledFooter>
            <Grid>
                <Button onClick={() => navigate('/about')}>About</Button>
            </Grid>
            <p>© 2024 3Scrum. All rights reserved.</p>
        </StyledFooter>
    );
}